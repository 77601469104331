/* eslint-disable */
///-------------------------------------------------------------------------------------------------
// summary:	Authentication service class
///-------------------------------------------------------------------------------------------------

import Api from './Api';
export default {
  
  login(credentials) {
    const response = Api().post('/login', credentials);
    return response;
  },
  
  logout(credentials) {
    const response = Api().post('/logout', credentials);
    return response;
  },
  
  // This just reauthorize the existing token (or tries, at least); 
  // A fresh token is returned, which we should replace our old token with
  authorize() {
    const response = Api().post("/authorize", {});
    return response;
  },
  // future use
  forgotPassword(email) {
    const response = Api().post("/forgotPassword", {email: email});
    return response;
  },
  // future: use
  // token is emailed to the user when "forgotPassword()" is used and should be entered here.  When you get the token, 
  // just try to reset it -- it will throw an error if it fails; success should result in a 200.
  resetPassword( email , token , newPassword ) {
    const response = Api().post( "/resetPassword" , {email:email, resetToken: token, newPassword: newPassword });
    return response;
  }
 
}
