<template>
  <div>
    <div v-if="!haveToken" class="login-form-container">
      <div>
        <div class="row">
          <div class="col-12 mb-2">
            <img src="@/assets/iPosCafe-Logo.png" class="login-logo" alt="iPos.Cafe by Newcare International" />
            <div class="col-12 mb-4 ml-auto mr-auto">
              <h3 style="color:white;">Welcome to iPOS Cafe</h3>
            </div>
          </div>

          <template v-if="domains.length == 0">
            <div class="col-12 login-form">
              <p class="font-weight-bolder">Sign in with your iCare account.</p>
              <div class="position-relative">
                <b-form-input class="email-input mb-3 pl-5" v-model="email" placeholder="Email"></b-form-input>
                <i class="fa fa-envelope email-icon" />
              </div>
              <div class="position-relative">
                <b-form-input class="password-input mt-0 pl-5" type="password" v-model="password" placeholder="Password" @keyup.enter="signIn"></b-form-input>
                <i class="fa fa-lock password-icon" />
              </div>
            </div>
            <div class="col-12 mt-4">
              <b-button class="sign-in-button" @click="signIn" @keyup.enter="signIn">Sign in</b-button>
            </div>
          </template>

          <template v-if="domains.length>0">
            <div class="col-12 w-100">
              <div class="domain-selections">
                <div>
                  <b-button @click='signinCancel()' variant="danger" class='btn btn-fill'>
                    Cancel
                  </b-button>
                  <h4>Select a Location</h4>
                  <p>Your account is associated with multiple locations.</p>
                </div>
                <hr>
                <div class="tenant-select" v-for="domain in domains" :key="domain.name">
                  <tenant-card class="show-hand app-card" @selectDomain='domainClick($event)' :domain="domain"></tenant-card>
                </div>
              </div>
            </div>
          </template>

          <div v-if="errorMessage" class="col-12 font-weight-bolder text-center mt-4 text-danger">{{ errorMessage }}</div>
        </div>
        <div style="margin-top:10em;text-align:center;">
          <a href="https://www.newcare.international" target="_blank">Built with <i class="fa fa-heart" style="color:red;" /> for you by us at Newcare International (an IOIHAN company)</a>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
/* eslint-disable */
import Authentication from '@/services/AuthenticationService'
import TenantCard     from "@/components/TenantCard.vue";

export default {
  name      : 'login',
  components: {
    TenantCard
  },
  data() {
    return {
      haveToken   : true,
      email       : "",
      password    : "",
      errorMessage: "",
      domain      : "",
      domains     : [],
    }
  },
  async mounted() {
    // if we have a token, verify it to be valid - if valid, then replace the one we have
    // with the new one we just got back and skip the login questions
    // then if/when we get the new token, replace it
    let token = this.getAccessToken();
    if(token) {
      try {
        this.setAccessToken(token); // also sets up axios
        let response = await Authentication.authorize();
        if(response.status === 200) {
          let token = response.data[ 'x-access-token' ];
          this.setAccessToken(token);
          if(await this.$root.getPosConfig()) {
            return this.home();
          }
        } else {
          this.errorMessage = response.data.message;                // oops, the token ain't no good -- let's drop the user at login to start over.
          this.removeAccessToken();
        }
      } catch(error) {
        console.log(error);
        this.errorMessage = "mounted: " + error.message
      }
    }

    this.haveToken = false;

  },
  methods: {
    // this is our "home" screen
    home() {
      this.$router.push({name: 'homeMenu'});
    },
    signinCancel() {
      this.domains      = [];
      this.domain       = "";
      this.errorMessage = "";
    },
    domainClick(domain) {
      this.errorMessage = "";
      this.domain       = domain;
      this.signIn();
    },
    getAccessToken() {
      let token = localStorage.getItem('x-access-token');
      return token;
    },
    setAccessToken(token) {
      localStorage.setItem('x-access-token', token);
    },
    removeAccessToken() {
      localStorage.removeItem('x-access-token');
    },
    // fixme: make global just like getPosConfig()
    // todo: and also get the items under the categories while we're in here
    // async getCategories( categories ) {
    //   if( categories ) {
    //     localStorage.setItem( "categoryList" , categories );
    //     await DAH.getCategories( categories );

    //     return;
    //   } else {
    //     alert( "oops! Called with no category categories" );
    //   }
    // },
    // Call this once login is successful;
    // First call does NOT get the lastUpdateId to force complete config retrival.
    // fixme: make this a global/root routine to get config in only one place.
    // async getPosConfig() {

    //   let categoryList;

    //   try {
    //     let lastUpdateId = localStorage.getItem( 'lastUpdateId' ) || '';

    //     if( ! localStorage.getItem( "posConfig" )) {
    //       lastUpdateId = "";
    //     }

    //     let response   = await GeneralService.getPosConfig( lastUpdateId ) // always get config on startup

    //     if( response ) {
    //       if( response.data && response.data.workstationConfig ) {
    //         categoryList = response.data.workstationConfig.categories.join(",");
    //       }
    //     }

    //     if( response.status === 204 ) {   // no data response. (we already have it)
    //       categoryList = localStorage.getItem( "categoryList" );
    //       await this.getCategories(categoryList);     // will be a nop() if we have it already
    //       return true;
    //     }

    //     if( response.status === 200  ) {
    //       let configData = response.data;

    //       if( Object.entries(configData).length === 0 && configData.constructor === Object) {   // Just in case it comes in empty.
    //         await this.getCategories(categoryList);
    //         return true;
    //       }

    //       // fixme: need to replace the api url somehow so we start using the one we just got back form config.
    //       // configData.apiUrl - url starts in constants, but may need to be hydrated to the store on startup -- dunno

    //       // now seperate out some of the config stuff we're going to seperatly without reloading the entire config - these get updated with every transaction
    //       localStorage.setItem( 'dailyTotals' , JSON.stringify( configData.dailyTotals ));
    //       delete configData.dailyTotals;

    //       localStorage.setItem( 'lastUpdateId', configData.lastUpdateId );
    //       localStorage.setItem( 'mainMenu', JSON.stringify(configData.workstationConfig.mainMenu));

    //       if( !localStorage.getItem( 'nextTransactionNumber' )) {
    //         localStorage.setItem( 'nextTransactionNumber' , configData.transactionNumber || configData.terminalId * 1000);
    //         delete configData.transactionNumber;
    //       }

    //       delete configData.workstationConfig.mainMenu;

    //       this.posConfig = configData;
    //       localStorage.setItem( 'posConfig', JSON.stringify(configData));

    //     } else {
    //       this.errorMessage = "posConfig 1:" +  response.data.message;
    //       return false;
    //     }
    //   } catch( error ) {  // if we're here, we have a 500+ status or something bad
    //     this.errorMessage = "posConfig 2:" + error.message
    //     return false;
    //   }

    //   localStorage.removeItem( "categories" );
    //   await this.getCategories( categoryList );

    //   return true;
    // },
    async signIn() {

      if(this.email.length === 0 || this.password.length === 0) {
        this.errorMessage = "Please enter email and password"
        return
      }

      let credentials = {
        'email'   : this.email,
        'password': this.password,
        'domain'  : this.domain
      }

      try {
        let response = await Authentication.login(credentials)

        if(response.status == 203) {
          this.removeAccessToken();
          this.domains = response.data;
          return
        }

        if(response.status === 200) {

          let userData = response.data;
          let token    = userData[ 'x-access-token' ];

          this.$root.login( userData );

        } else {
          this.removeAccessToken();
          await this.$root.alert( response.data.message || "Unable to log in", "error");
          // this.errorMessage = response.data.message || "Unable to log in";
        }

      } catch(err) {
        // this.errorMessage = "signIn: " + err.message;
        await this.$root.alert( err.message || "Unable to log in", "error");
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.login-form-container {


  height: 100vh;
  width: 100vw;

  overflow: auto;
  overflow-x: hidden;

  background: -moz-linear-gradient(top, rgba(101, 157, 189, 1) 0%, rgba(101, 157, 189, 0.9) 52%, rgba(213, 222, 229, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(101, 157, 189, 1) 0%, rgba(101, 157, 189, 0.9) 52%, rgba(213, 222, 229, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(101, 157, 189, 1) 0%, rgba(101, 157, 189, 0.9) 52%, rgba(213, 222, 229, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#659dbd', endColorstr='#d5dee5', GradientType=0); /* IE6-9 */

  //color: white;
  text-align: center;

  .login-logo {
    display: block;
    max-width: 400px;
    width: 60%;
    text-align: center;
    border-radius: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 1em;
  }

  .domain-selections {
    width: 80%;
    margin: auto;
    text-align: left;

    button {
      border-radius: 1em;
    }

    h4 {
      color: white;
      margin-top: 1em;
      margin-bottom: 0;
    }

    p {
      color: black;
      margin: 0;
      padding: 0;
    }

  }

  .sign-in-button {
    min-width: 140px;
    max-width: 250px;
    width: 10%;

    height: calc(1.5em + 1.75rem + 2px) !important;
    border-radius: 3.25rem !important;
    background: #3b3b76;
    //border: #659DBD;
  }

  .sign-in-button:hover {
    background: #8080f5;
    border-color: #8080f5;
  }

  .login-form {
    color: white;
    //top: 50%;
    //left: 50%;
    margin: auto;
    text-align: center;
    max-width: 400px;
    overflow: auto;

    h3 {
      //color: white;
    }

    p {
      //color: white;
      // todo: center this
    }


    .email-input {
      border-radius: 3.25rem !important;
      height: calc(1.5em + 1.75rem + 2px) !important;
      border-bottom: 0;
    }

    .password-input {
      border-radius: 3.25rem !important;
      height: calc(1.5em + 1.75rem + 2px) !important;
    }


    //.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    //  color: #fff;
    //  background-color: #659DBD;
    //  border-color: #659DBD;
    //}

    .email-icon {
      color: gray;
      position: absolute;
      top: 17px;
      left: 20px;
      font-size: 19px;
    }

    .password-icon {
      color: gray;
      position: absolute;
      top: 15px;
      left: 20px;
      font-size: 19px;
    }
  }
}
</style>
